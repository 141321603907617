export var ModelCode;
(function (ModelCode) {
    ModelCode["USER"] = "USER";
    ModelCode["PERMISSION"] = "PERMISSION";
    ModelCode["PERMISSION_GROUP"] = "PERMISSION_GROUP";
    ModelCode["KPI"] = "KPI";
    ModelCode["WORKTIME"] = "WORKTIME";
    ModelCode["CALLS"] = "CALL";
    ModelCode["MY_WORKTIME"] = "MY_WORKTIME";
    ModelCode["STAT_CLIENT"] = "STAT_CLIENT";
    ModelCode["STAT_CLIENT_RESPONSE"] = "STAT_CLIENT_RESPONSE";
    ModelCode["ANALYTICS"] = "ANALYTICS";
    ModelCode["TODO"] = "TODO";
    ModelCode["NOTIFICATION"] = "NOTIFICATION";
    ModelCode["OFFICE"] = "OFFICE";
    ModelCode["NOTE"] = "NOTE";
    ModelCode["ACESS_USER_CALL"] = "ACESS_USER_CALL";
    ModelCode["CODE"] = "CODE";
    ModelCode["OPERATION"] = "OPERATION";
    ModelCode["BALANCE_ANALYTICS"] = "BALANCE_ANALYTICS";
    ModelCode["CASH_DESK_PAYMENT"] = "CASH_DESK_PAYMENT";
    ModelCode["FOLDER"] = "FOLDER";
    ModelCode["SESSION"] = "SESSION";
    ModelCode["FILE_UPLOAD"] = "FILE_UPLOAD";
    ModelCode["AGENT_PERFORMANCE"] = "AGENT_PERFORMANCE";
    ModelCode["CONFIG_BONUS_AMOUNT"] = "BONUS_AMOUNT";
    ModelCode["PDF_CONTENT"] = "PDF_CONTENT";
    ModelCode["PAYMENT_NOTE"] = "PAYMENT_NOTE";
    ModelCode["BOOK"] = "BOOK";
    ModelCode["LEVEL"] = "LEVEL";
    ModelCode["SECTION"] = "SECTION";
    ModelCode["BOOK_STOCK"] = "BOOK_STOCK";
    ModelCode["BOOK_SALE_TRANSACTION"] = "BOOK_SALE_TRANSACTION";
    ModelCode["DEPARTMENT"] = "DEPARTMENT";
    ModelCode["DEPARTMENT_WORKTIME"] = "DEPARTMENT_WORKTIME";
    ModelCode["BOOK_CASH_DESK_PAYMENT"] = "BOOK_CASH_DESK_PAYMENT";
    ModelCode["BOOK_ANALYTICS"] = "BOOK_ANALYTICS";
})(ModelCode || (ModelCode = {}));
export var MethodCode;
(function (MethodCode) {
    MethodCode["CREATE"] = "CREATE";
    MethodCode["LIST"] = "LIST";
    MethodCode["VIEW"] = "VIEW";
    MethodCode["EDIT"] = "EDIT";
    MethodCode["DELETE"] = "DELETE";
})(MethodCode || (MethodCode = {}));
