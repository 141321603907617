export var FrontType;
(function (FrontType) {
    FrontType["TEXTAREA"] = "textarea";
    FrontType["RADIO"] = "radio";
    FrontType["CHECKBOX"] = "checkbox";
    FrontType["SELECT"] = "select";
    FrontType["INPUT"] = "input";
    FrontType["SWITCH"] = "switch";
    FrontType["NONE"] = "";
})(FrontType || (FrontType = {}));
